import { useEffect, useState } from "react";
import { getPassKey } from "./secrets";
import Chat from "./Chat";

function MyAIApp() {
    const [enableChat, setEnableChat] = useState(false);
    let handleSubmit = (event) => {
        event.preventDefault()
        let enteredKey = event.target.name.value
        if(enteredKey === getPassKey()) {
            setEnableChat(true)
        } else {
            alert("Wrong passkey")
        }
    }
  return (
    <div className="MyAIApp">
        <form onSubmit={handleSubmit}>
            <label>
                Passkey:
                <input type="text" name="name"/>
            </label>
            <input type="submit" value="Submit"/>
        </form>

        { enableChat ? <Chat /> : null}
    </div>
  );
}

export default MyAIApp;

import axios from "axios";
import { useState } from "react";
import { ClipLoader } from "react-spinners";

function Chat() {
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);
    let handleSubmit = (event) => {
        event.preventDefault()
        let requestObject = {
            text: event.target.prompt.value,
            token: "325142yaja"
        }
        setLoading(true)
        axios.post("https://backend.ajaysappverse.com/", requestObject).then((res) => {
            if(res.data.candidates) {
                setResponse(res.data.candidates[0].content.parts[0].text)
                console.log(res.data.candidates[0].content.parts[0].text)
            } else {
                setResponse(res.data)
            }
            setLoading(false)
        })
    }
   return(
    <div style={{margin: '40px'}} className="Chat">
        <h1>Chat</h1>
        <form onSubmit={handleSubmit}>
            <label>
                Prompt:<br></br>
                <input type="text" name="prompt"/>
            </label>
            <input type="submit" value="Submit"/>
        </form>

        { loading ? <ClipLoader color="#000000" size={150} /> : 
        <div style={{marginTop: '40px'}} className="h1 bg-secondary text-white text-center p-2">
          { response }
        </div> }
    </div>
   );
}

export default Chat;